// This file is run after everything else

function runMain(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runMain(F, W);
        }, 10);
        return;
    }

    // Create variables
    var $maps;

    // Create a variable for match height elements
    var $matchHeight;

    // Create a global model
    var GlobalModelConstructor = F.model.make({
        activeSpeaker: 'string',
        triggerMatchHeight: 'int'
    });
    F.GlobalModel = new GlobalModelConstructor();

    // Initialize mobile nav
    F.controller.construct('MobileNav', {
        el: 'body'
    });

    // Initialize desktop nav scroll
    F.controller.construct('DesktopNavScroll', {
        el: $(window)
    });

    // Initialize any countdowns on page
    /*$('.js-countdown').each(function() {
        F.controller.construct('CountDown', {
            el: this
        });
    });*/

    // Initialize any speakers
    $('.js-speaker').each(function() {
        F.controller.construct('Speaker', {
            el: this
        });
    });

    // Initialize tabs
    $('.js-tabbed-content').each(function() {
        F.controller.construct('TabbedContent', {
            el: this
        });
    });

    // Initialize read more items
    $('.js-read-more').each(function() {
        F.controller.construct('ReadMore', {
            el: this
        });
    });

    // Check for map
    $maps = $('.js-map');

    // If there are maps
    if ($maps.length) {
        // Load the Google Maps api
        $.ajax({
            url: '/ajax/google-maps-geocode-api',
            success: function(obj) {
                var parser = document.createElement('a');
                parser.href = obj.scriptUrl;
                F.assets.load({
                    root: parser.protocol + '//' + parser.host,
                    js: parser.pathname,
                    success: function() {
                        // Construct controllers
                        $maps.each(function() {
                            F.controller.construct('Map', {
                                el: this
                            });
                        });
                    }
                });
            }
        });
    }

    // Run anchor links
    $('a[href^="#"]').add('.js-anchor-link').each(function() {
        F.controller.construct('AnchorLink', {
            el: this
        });
    });

    // Check for incoming anchor
    if (window.anchor) {
        F.controller.construct('InitialAnchor');
    }

    // Run match height if there are any match height elements
    $matchHeight = $('.js-match-height');

    if ($matchHeight.length) {
        F.controller.construct('MatchHeight', {
            $elements: $matchHeight
        });
    }

    $('.JS-InstagramFeed').each(function() {
        F.controller.construct('InstagramFeed', {
            el: this
        });
    });
}

runMain(window.FAB, window);
