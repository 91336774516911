function runInstagramFeed(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runInstagramFeed(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('InstagramFeed', {
        init: function() {
            var self = this;

            $.ajax({
                url: '/ajax/instagram-feed',
                dataType: 'JSON',
                success: function(json) {
                    self.process(json);
                }
            });
        },

        process: function(json) {
            var self = this;
            var counter = 1;

            self.$el.find('.JS-InstagramFeed__Item').each(function() {
                var $el = $(this);
                var data = json['data_' + counter];
                var $link = $el.find('.JS-InstagramFeed__Link');
                var $img = $el.find('.JS-InstagramFeed__Img');
                var $placeholder = $el.find('.JS-InstagramFeed__Placeholder');

                $link.attr('href', data.link);

                $img.attr('src', data.displaySrc);

                $img.show();

                $placeholder.hide();

                counter++;
            });
        }
    });
}

runInstagramFeed(window.FAB, window);
